<script setup lang="ts">
import type { Map, Icon } from 'leaflet'

const BaseMap = () => import('leaflet')

const props = withDefaults(
  defineProps<{
    coords?: { lat: number | string; lng: number | string }
    markerCoords?: {
      gpslatitude: number
      gpslongitude: number
      houseId: number
    }[]
  }>(),
  {
    coords: () => ({ lat: '', lng: '' }),
    markerCoords: () => [],
  },
)

let baseMap: Map
let leaflet: Awaited<ReturnType<typeof BaseMap>>

const attribution =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
const circleOptions = ref({
  color: '#202020',
  fillColor: '#202020',
  fillOpacity: 0.6,
  radius: 1000,
  stroke: false,
})
const icon = ref<Icon>()
const mapElement = ref<HTMLDivElement>()
const styleUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
const zoomMap = {
  maxZoom: 14,
  zoom: 12,
  zoomAnimation: false,
  zoomSnap: 0.5,
}
const isCoordValid = computed(
  () =>
    isLatitude(Number(props.coords.lat)) &&
    isLongitude(Number(props.coords.lng)),
)
const markers = computed(() => ({
  position: {
    lat: parseFloat(props.coords.lat.toString()),
    lng: parseFloat(props.coords.lng.toString()),
  },
}))

onMounted(async () => {
  await BaseMap()
    .then((bMap) => {
      leaflet = bMap
      icon.value = leaflet.icon({
        iconUrl:
          'https://cdn.lecollectionist.com/lc/production/assets/images/housemarker.svg',
        iconSize: [40, 40],
      })

      if (mapElement.value) {
        if (props.markerCoords.length === 0) {
          if (isCoordValid.value) {
            baseMap = leaflet
              .map('BaseMap', {
                ...zoomMap,
                minZoom: 7,
                zoomControl: false,
                scrollWheelZoom: false,
              })
              .setView(
                [markers.value.position.lat, markers.value.position.lng],
                13,
              )

            setLeafletHouse()
          }
        } else {
          baseMap = leaflet
            .map('BaseMap', {
              ...zoomMap,
            })
            .setView([46.90296, 1.90925], 6)
          const stamenToner = leaflet.tileLayer(styleUrl, {
            attribution,
          })

          baseMap.addLayer(stamenToner)

          leaflet.control
            .zoom({
              position: 'bottomright',
            })
            .addTo(toRaw(baseMap))
        }
      }
    })
    .catch((err) => {
      console.warn(err)
    })
})

const isLatitude = (lat: number) => isFinite(lat) && Math.abs(lat) <= 90
const isLongitude = (lng: number) => isFinite(lng) && Math.abs(lng) <= 180

const setLeafletHouse = () => {
  if (!leaflet || !baseMap) return
  leaflet
    .tileLayer(styleUrl, {
      attribution,
    })
    .addTo(toRaw(baseMap))

  leaflet.control
    .zoom({
      position: 'bottomright',
    })
    .addTo(toRaw(baseMap))

  leaflet
    .circle([markers.value.position.lat, markers.value.position.lng], {
      ...circleOptions.value,
    })
    .addTo(toRaw(baseMap))

  leaflet
    .marker([markers.value.position.lat, markers.value.position.lng], {
      icon: icon.value,
    })
    .addTo(toRaw(baseMap))
}
</script>

<template>
  <div id="BaseMap" ref="mapElement" class="base-map" />
</template>

<style src="leaflet/dist/leaflet.css"></style>

<style>
.base-map {
  @apply z-10;
}

@screen md {
  .base-map {
    height: 70vh;
  }
}
@screen lg {
  .base-map {
    @apply h-full;
  }
}
.leaflet-container a {
  @apply text-gray-700 no-underline;
}
</style>
